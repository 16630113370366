
import Vue from 'vue';
import {mapGetters} from "vuex";
import {Solicitud} from '@/models/Solicitud';
import rules from "@/mixins/rules";
import CardAdquiriente from '@/components/Isabi/CardAdquiriente.vue';
import CardEnajenante from '@/components/Isabi/CardEnajenante.vue';
import {Enajenante} from "@/models/Enajenante";
import { Adquiriente } from '@/models/Adquiriente';
import SolicitudService from '@/services/SolicitudService';
import {VForm} from '@/types/formvalidate';
// @ts-ignore
import EventBus from "@/plugins/event-bus";
import SimService from "@/services/SimService";
import {CatalogoService} from "@/services/CatalogoService";
import DiasService from "@/services/DiaService";
import CustomFileIsabi from "@/components/Common/CustomFileIsabi.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import moment from 'moment';
import moment2 from 'moment-business-days';
import UserService from "@/services/UserService";
import numeric from '@/directives/numeric';

export default Vue.extend({
  name: 'FormIsabi',
  mixins: [rules],
  components: {
    CardAdquiriente,
    CardEnajenante,
    CustomFileIsabi
  },
  props: {
    isabiId: {
      default: 0
    },
    editar:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    Notario: [],
    serviceCatTipoOperacion: new CatalogoService('catalogos/tipo-operacion'),
    serviceEntidades: new CatalogoService('catalogos/entidad'),
    serviceMunicipios: new CatalogoService('catalogos/municipio'),
    serviceInpc: new CatalogoService('catalogos/inpc'),
    serviceTasaRecargos: new CatalogoService('catalogos/tasa-recargos'),
    serviceUma: new CatalogoService('catalogos/uma'),
    serviceTasaImpuestos: new CatalogoService('catalogos/tasa-impuestos'),
    serviceDescuentosSubsidios: new CatalogoService('catalogos/descuento-subsidio'),
    catalogos: {
      entidades: [],
      municipios: [],
      entidadDefault: 23,
      municipioDefault: 4,
    },
    show: false,
    menu1: false,
    menu2: false,
    showForm: true,
    loading: false,
    editable: false,
    lectura: false,
    delete_enajenantes: [] as any,
    delete_adquirientes: [] as any,
    formSolicitud: new Solicitud(),
    formDefault: new Solicitud(),
    terminated: false, //verificar
    formAdquirientes: [] as Adquiriente[],
    formEnajenantes: [] as Enajenante[],
    avaluo: 0,
    avaluo_text: '',
    impuesto: 0.02,
    impuesto_neto: 0.00,
    impuesto_neto_text: '',
    base_gravable: 0.0,
    total_pagar: 0.0,
    base_gravable_text: "",
    total_pagar_text: "",
    tipo_subsidio: 0,
    aplica_sub: false,
    aplica_porcentaje: false,
    subsidio: 0,
    subsidio_text: "",
    limite_subsidio: 0,
    limite_subsidio_text: "",
    valor_uma: 0,
    precioPactado: 0.00,
    avaluoCatastral: 0.00,
    avaluoBancario: 0.00,
    fecha_limite: '',
    fecha_actual: moment(),
    fecha_actual_text: '',
    subsidio_vigente: null,
    subsidios: [{
      id: 1,
      text: "Vivienda de interés social",
      limite: 0
    },
      {
        id: 2,
        text: "Vivienda popular",
        limite: 0
      }
    ],
    diasInhabiles: [],
    diasInhabilesUsados: [],
    operaciones: [],
    currencyMask: createNumberMask({
      prefix: '$',
      allowDecimal: true,
      includeThousandsSeparator: true,
      allowNegative: false,
      thousandsSeparatorSymbol: ",",
    }),
    formRechazo: {
      id: '',
      motivo_rechazo: '',
    },
    dialog : false,
    bandera_calcular: false,
    tieneRecargos: false,
    inpc: {},
    inpc_texto: '',
    inpc_label: '',
    recargos: {},
    recargos_texto: '',
    recargos_label: '',
    id_usuario: 0,
    dialog_avaluos: false,
    btnDialog_avaluos: false,
    avaluos: [],
    bloquear: false,
    catUmas: []
  }),
  watch: {
    valorMaximo: {
      handler: function (valor) {
        const newVal = parseFloat(valor);
        if (!isNaN(newVal)) {
          this.avaluo = newVal;
          this.avaluo_text = '$' + new Intl.NumberFormat().format(newVal);
        }
      },
      immediate: true
    }
  },
  computed: {
    valorMaximo() {
      let precioPactado = parseFloat(this.formSolicitud.inmueble.precio_pactado.replace(/[^\d.]/g, ''));
      let avaluoCatastral = parseFloat(this.formSolicitud.inmueble.avaluo_catastral.replace(/[^\d.]/g, ''));
      let avaluoBancario = parseFloat(this.formSolicitud.inmueble.avaluo_bancario.replace(/[^\d.]/g, ''));
      if (isNaN(precioPactado)) {
        precioPactado = 0;
      }
      let maximo = Math.max(precioPactado,avaluoCatastral,avaluoBancario);
      return maximo;
    },
    form(): VForm {
      return this.$refs.form as VForm
    },
    formRe(): VForm {
      return this.$refs.formRechazo as VForm;
    },
    ...mapGetters({
      usuario: 'usuario', id: 'id', mini: 'sidebar', role:'role'
    }),
  },
  mounted() {
    this.getAllData();
    this.obtenerDias();
    if(this.isabiId.toString() == 'add'){
      this.id_usuario = this.id;
      this.getNotario();
    }
    //this.getData();
  },
  methods: {
    handleFileUploadSuccess(data: any) {
      // type FormUserKey = keyof typeof this.formUser;
      const typeFile = data.typeFile;
      const message = data.message;
      // @ts-ignore
      this.formSolicitud[typeFile].data = null;
      this.$toast.success(message);
    },
    handleFileUploadError (data: any){
      const message = data.message;
      this.$toast.error(message);
    },
    async getData() {
      if (this.isabiId.toString() != 'add') {
        this.lectura = true;
        let { data } = await SolicitudService.getById(this.isabiId);
        if (data.id != null) {
          this.formSolicitud.id = data.id;
          this.formSolicitud.motivo_rechazo = data.motivo_rechazo;
          this.formSolicitud.estatus = data.estatus_id;
          this.formSolicitud.escritura = data.escritura;
          this.formSolicitud.inmueble = data.inmueble;
          this.formSolicitud.tipo_operacion_id = data.tipo_operacion_id;
          this.formSolicitud.calculo.id = data.calculo.id;
          //Obtener notario
          this.id_usuario = data.usuario_id;
          this.getNotario();

          //Checar si tiene INPC
          if(data.calculo.impuestos != null){
            this.formSolicitud.calculo.impuesto_actualizado_id = data.calculo.impuestos.id;
          }
          //Checar si tiene recargo
          if(data.calculo.recargos != null){
            this.formSolicitud.calculo.recargo_id = data.calculo.recargos.id;
          }

          this.cambiaFecha();

          if(data.calculo.aplica_subsidio){
            this.aplica_sub = data.calculo.aplica_subsidio;
            // @ts-ignore
            this.tipo_subsidio = parseInt(data.calculo.tipo_subsidio);
            this.selectSub();
          }
          
          let adquirientes = data.adquirientes;
          let _this = this;
          // @ts-ignore
          adquirientes.forEach(function callback(value, index) {
            value.tipo_persona_id = value.tipo_persona_id.toString();
            _this.formAdquirientes.push(new Adquiriente());
            _this.setAdquirientes(value,index);
          });

          let enajenantes = data.enajenantes;
          // @ts-ignore
          enajenantes.forEach(function callback(value, index) {
            value.tipo_persona_id = null;
            _this.formEnajenantes.push(new Enajenante());
            _this.setEnajenantes(value,index);
          });

          //Archivos
          let archivos = data.archivos;
          archivos.forEach(function callback(value: any) {
            if(value.tipo_archivo == 'escritura_publica'){
              _this.formSolicitud.escritura_publica.data = value;
            }
            if(value.tipo_archivo == 'cedula_catastral'){
              _this.formSolicitud.cedula_catastral.data = value;
            }
            if(value.tipo_archivo == 'pago_predial'){
              _this.formSolicitud.pago_predial.data = value;
            }
            if(value.tipo_archivo == 'constancia_no_adeudo'){
              _this.formSolicitud.constancia_no_adeudo.data = value;
            }
            if(value.tipo_archivo == 'avaluo_comercial'){
              _this.formSolicitud.avaluo_comercial.data = value;
            }
            if(value.tipo_archivo == 'certificado_obras'){
              _this.formSolicitud.certificado_obras.data = value;
            }
            if(value.tipo_archivo == 'zofemat'){
              _this.formSolicitud.zofemat.data = value;
            }
          });

          setTimeout(() => {
            // @ts-ignore
            this.fecha_actual = moment(data.fecha_registro);
            // @ts-ignore
            this.fecha_actual_text = moment(data.fecha_registro).format("YYYY-MM-DD");
            this.calcular();
          }, 5000);

        }
        //this.formUser = Object.assign({}, this.formDefault);
      }
      else{
        this.formAdquirientes = [new Adquiriente()];
        this.formEnajenantes = [new Enajenante()];
      }
    },
    //@ts-ignore
    formatInput(obj, field) {
      //@ts-ignore
      obj[field] = obj[field].replace(/[$\s,]/g, '');
    },
    async getNotario() {
      await UserService.getById(this.id_usuario).then((response) => {
          if(response.data){
            if(response.data.user_owner){
              this.formSolicitud.auxiliar.id = response.data.id;
              this.formSolicitud.auxiliar.auxiliary_type = response.data.auxiliary_type;
              this.formSolicitud.auxiliar.email = response.data.email;
              this.formSolicitud.usuario.id = response.data.user_owner.id;
              this.formSolicitud.usuario.nombre = response.data.user_owner.nombre + " " + response.data.user_owner.primer_apellido + " " + response.data.user_owner.segundo_apellido;
              this.formSolicitud.usuario.rfc = response.data.user_owner.rfc;
              this.formSolicitud.usuario.curp = response.data.user_owner.curp;
              this.formSolicitud.usuario.telefono = response.data.user_owner.telefono;
              this.formSolicitud.usuario.nombre_notaria = response.data.user_owner.notaria.descripcion;
              this.formSolicitud.usuario.direccion_notaria = response.data.user_owner.notaria.direccion;
              this.formSolicitud.usuario.poblacion = response.data.user_owner.notaria.localidad;
              this.formSolicitud.usuario.entidad_id = response.data.user_owner.notaria.entidad_id;
              this.formSolicitud.usuario.notaria_id = response.data.user_owner.notaria.id;
              this.formSolicitud.escritura.entidad_id = response.data.user_owner.notaria.entidad_id;
              this.formSolicitud.escritura.poblacion = response.data.user_owner.notaria.localidad;
            }else{
              this.formSolicitud.usuario.id = response.data.id;
              this.formSolicitud.usuario.nombre = response.data.nombre + " " + response.data.primer_apellido + " " + response.data.segundo_apellido;
              this.formSolicitud.usuario.rfc = response.data.rfc;
              this.formSolicitud.usuario.curp = response.data.curp;
              this.formSolicitud.usuario.telefono = response.data.telefono;
              this.formSolicitud.usuario.nombre_notaria = response.data.notaria.descripcion;
              this.formSolicitud.usuario.direccion_notaria = response.data.notaria.direccion;
              this.formSolicitud.usuario.poblacion = response.data.notaria.localidad;
              this.formSolicitud.usuario.entidad_id = response.data.notaria.entidad_id;
              this.formSolicitud.usuario.notaria_id = response.data.notaria.id;
              this.formSolicitud.escritura.entidad_id = response.data.notaria.entidad_id;
              this.formSolicitud.escritura.poblacion = response.data.notaria.localidad;
            }
          }
      });
    },
    async obtenerDias() {
      await DiasService.getDias().then((response) => {
          if(response.data){
            this.diasInhabiles = response.data.data.map(function (x: any) {
              return x.dia;
            });
          }
          moment2.updateLocale('es', {
            holidays: this.diasInhabiles,
            holidayFormat: 'YYYY-MM-DD',
            workingWeekdays: [1, 2, 3, 4, 5]
          });
          
      });
    },
    formatDate(date: any) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    setAdquirientes(adquiriente: Adquiriente, index: any) {
      this.formAdquirientes[index] = Object.assign({}, adquiriente);
    },
    setEnajenantes(enajenante: Enajenante, index: any) {
      this.formEnajenantes[index] = Object.assign({}, enajenante);
    },
    incrementCardAdquiriente() {
      this.formAdquirientes.push(new Adquiriente());
    },
    incrementCardEnajenante() {
      this.formEnajenantes.push(new Enajenante());
    },
    deleteAdquiriente(index: number) {
      // devuelve el indice del arreglo empezado  la inicialización siempre en
      if (this.formAdquirientes.length > 1) {
        this.formAdquirientes.splice(index, 1);
      } else {
        this.$toast.warning(`Debe registrar por lo menos un adquiriente`);
      }
    },
    deleteEnajenante(index: number) {
      // devuelve el indice del arreglo empezado  la inicialización siempre en
      if (this.formEnajenantes.length > 1) {
        this.formEnajenantes.splice(index, 1);
      } else {
        this.$toast.warning(`Debe registrar por lo menos un enajenante`);
      }
    },
    async consultarCatastro(event:any) {
      let _this = this;
      if(event == 1){
        _this.formSolicitud.inmueble.clave_catastral = "";
      }
      else if(event == 2){
        _this.formSolicitud.inmueble.folio = "";
      }
      let claveF = _this.formSolicitud.inmueble.folio;
      let claveC = _this.formSolicitud.inmueble.clave_catastral;
      let clave  = claveF + "_" + claveC;
      try {
        const [infoPredioRes] = await Promise.all([
          SimService.getInfoPredio(clave)
        ]);

        this.formSolicitud.inmueble.ubicacion = '';
        this.formSolicitud.inmueble.poblacion = '';
        this.bloquear = false;
        if (infoPredioRes.data.success) {
          if(infoPredioRes.data.bloquear){
            if(infoPredioRes.data.tipo_bloqueo == 1){
              this.$toast.error("¡El último avalúo no puede ser mayor a tres años! Por favor acuda al ayuntamiento para actualizar sus datos.");
              this.bloquear = true;
            }
            else if(infoPredioRes.data.tipo_bloqueo == 2){
              this.$toast.error("¡El pago del predial no esta al corriente!");
              this.bloquear = true;
              this.$toast.warning("El último pago del predial fue en el bimestre " + infoPredioRes.data.data.predial.bimestre + " del año " + infoPredioRes.data.data.predial.anio);
            }
          }
          else{
            if(infoPredioRes.data.data != null){
              if(event == 1){
                this.formSolicitud.inmueble.clave_catastral = "";
                this.formSolicitud.inmueble.clave_catastral = infoPredioRes.data.data.datos_predio.clave_catastral;
              }
              else if(event == 2){
                this.formSolicitud.inmueble.folio = "";
                this.formSolicitud.inmueble.folio = infoPredioRes.data.data.datos_predio.folio;
              }
              this.$toast.info("El último pago del predial fue en el bimestre " + infoPredioRes.data.data.predial.bimestre + " del año " + infoPredioRes.data.data.predial.anio);
              this.formSolicitud.inmueble.ubicacion = infoPredioRes.data.data.datos_predio.calle + " " + infoPredioRes.data.data.datos_predio.colonia;
              this.formSolicitud.inmueble.numero    = infoPredioRes.data.data.datos_predio.num_predio;
              this.formSolicitud.inmueble.poblacion = infoPredioRes.data.data.datos_predio.localidad;
              this.formSolicitud.inmueble.avaluo_catastral          = "";
              this.formSolicitud.inmueble.avaluo_catastral          = infoPredioRes.data.data.avaluos.length > 0? "$" + infoPredioRes.data.data.avaluos[0].valor_catastral : "";
              this.formSolicitud.inmueble.avaluo_catastral_original = infoPredioRes.data.data.avaluos.length > 0? "$" + infoPredioRes.data.data.avaluos[0].valor_catastral : "";
              this.avaluos = infoPredioRes.data.data.avaluos;
              // @ts-ignore
              this.formSolicitud.inmueble.fecha_avaluo = this.avaluos[0]? this.avaluos[0].fecha : null;
              this.dialog_avaluos = true;
              this.btnDialog_avaluos = true;
              //Asignar enajenantes
              let enajenantes = infoPredioRes.data.data.propietarios.actuales;
              if(enajenantes.length > 0){
                _this.formEnajenantes = [] as Enajenante[];
              }
               // @ts-ignore
              enajenantes.forEach(function callback(value, index) {
                const dataFromJson = {
                  id: 0,
                  tipo_persona_id: 0,
                  nombre: value.nombre,
                  domicilio: value.direccion
                };
                let enajenante = new Enajenante();
                enajenante.setData(dataFromJson);
                _this.formEnajenantes.push(new Enajenante());
                _this.setEnajenantes(enajenante,index);
               });
            }
          }         
        }
        else{
          this.$toast.error("Predio no encontrado");
        }
        
      } catch (error) {
        // Maneja el error apropiadamente según tu lógica de la aplicación
        console.error("Error al obtener datos:", error);
      }
    },
    close() {
      this.$router.push({name: 'isabi'}).catch(() => {
      });
    },
    async getAllData() {
      try {
        // Obtener el año actual
        var fechaActual = new Date();
        let dato = {search: fechaActual.getFullYear()}
        const [operacionesRes, entidadesRes, municipiosRes, umaRes, tasaImpuestoRes, descuentosSubRes] = await Promise.all([
          this.serviceCatTipoOperacion.getAll(),
          this.serviceEntidades.getAll(),
          this.serviceMunicipios.getAll(),
          this.serviceUma.getAll(),
          this.serviceTasaImpuestos.getAll(dato),
          this.serviceDescuentosSubsidios.getAll()
        ]);
        //Umas
        if(umaRes.data.length > 0){  
          this.catUmas   = umaRes.data;
          this.valor_uma = umaRes.data[0].valor_diario;
        }
        //no hay uma vigente
        else{
          this.$toast.warning("No hay registro de uma vigente");
        }
        //Tasa impuestos
        if(tasaImpuestoRes.data.length > 0){  
          this.impuesto = parseFloat(tasaImpuestoRes.data[0].valor);
        }
        //no hay tasa
        else{
          this.$toast.warning("No hay registro de impuesto vigente");
        }
        //Descuentos subsidios
        if(descuentosSubRes.data.length > 0){
          this.subsidio_vigente = descuentosSubRes.data[0];
        }
        
        this.operaciones = operacionesRes.data;
        
        this.catalogos.entidades = entidadesRes.data;
        this.catalogos.municipios = municipiosRes.data;

        this.formSolicitud.inmueble.entidad_id = 23;
        this.formSolicitud.inmueble.municipio_id = 4;
        this.getData();

      } catch (error) {
        console.error("Error al obtener datos:", error);
        // Maneja el error apropiadamente según tu lógica de la aplicación
      }
    },
    async save(){
      for (let index: number = 0; index < this.formAdquirientes.length; index++) {
        //@ts-ignore
        this.$refs.formAdquiriente[index].validate();
      }
      // for (let index: number = 0; index < this.formEnajenantes.length; index++) {
      //   //@ts-ignore
      //   this.$refs.formEnajenante[index].validate();
      // }
      //@ts-ignore
      if (this.form.validate()) {
        //Validar cuando la base gravable es cero
        if(this.formSolicitud.calculo.base_gravable == 0){

        }
        if(this.bandera_calcular == false){
          this.$toast.warning("Debe calcular los impuestos");
          return;
        }
        this.formSolicitud.adquirientes              = Object.assign({},this.formAdquirientes);
        this.formSolicitud.enajenantes               = Object.assign({},this.formEnajenantes);
        this.formSolicitud.calculo.impuesto          = this.impuesto;
        this.formSolicitud.calculo.base_gravable     = this.base_gravable;
        this.formSolicitud.calculo.impuesto_neto     = this.impuesto_neto;
        this.formSolicitud.calculo.total_a_pagar     = parseFloat(this.total_pagar.toFixed(2));
        this.formSolicitud.inmueble.precio_pactado   = this.formSolicitud.inmueble.precio_pactado.replace(/[^\d.]/g, '');
        this.formSolicitud.inmueble.avaluo_catastral = this.formSolicitud.inmueble.avaluo_catastral.replace(/[^\d.]/g, '');
        this.formSolicitud.inmueble.avaluo_bancario  = this.formSolicitud.inmueble.avaluo_bancario.replace(/[^\d.]/g, '');
        this.formSolicitud.inmueble.avaluo_catastral_subdivision = this.formSolicitud.inmueble.avaluo_catastral_subdivision? this.formSolicitud.inmueble.avaluo_catastral_subdivision.replace(/[^\d.]/g, '') : '';

        let formdata = Object.assign({}, this.formSolicitud);
        formdata.usuario.telefono = formdata.usuario.telefono.replace("(", "").replace(")", "").replaceAll(" ", "");

        let claves = Object.keys(formdata.adquirientes);
        for(let i=0; i< claves.length; i++){
          let clave = claves[i];
          if(formdata.adquirientes[parseInt(clave)].telefono){
            formdata.adquirientes[parseInt(clave)].telefono = formdata.adquirientes[parseInt(clave)].telefono
            .replace("(", "")
            .replace(")", "")
            .replaceAll(" ", "");
          }
        }

        // let claves2 = Object.keys(formdata.enajenantes);
        // for(let i=0; i< claves2.length; i++){
        //   let clave = claves2[i];
        //   formdata.enajenantes[parseInt(clave)];
        //   if(formdata.enajenantes[parseInt(clave)].telefono){
        //     formdata.enajenantes[parseInt(clave)].telefono = formdata.enajenantes[parseInt(clave)].telefono
        //     .replace("(", "")
        //     .replace(")", "")
        //     .replaceAll(" ", "");
        //   }
        // }

        let data_pre = null;
        data_pre = await SolicitudService.create(formdata);
        let { data } = data_pre;
        if (data.success) {
          this.$toast.success(data.message);
          this.$router.push({name: 'isabi'}).catch(() => {});
        }
        else {
          if(data.message.includes('adquirientes')){
            this.$toast.warning('Faltan datos de los adquirientes');
          }else{
            if(data.message.includes('enajenantes')){
              this.$toast.warning('Faltan datos de los enajenantes');
            }else{
              this.$toast.warning(data.message);
            }
          }
        }

      }

    },
    cambiaFecha(){
      this.bandera_calcular = false;
      let autorizacion = moment2(this.formSolicitud.escritura.fecha_autorizacion);
      let escritura = moment2(this.formSolicitud.escritura.fecha_escritura);
      //Comparar que la fecha de autorización no sea menor a la fecha de escritura
      if(this.formSolicitud.escritura.fecha_escritura == null || this.formSolicitud.escritura.fecha_escritura == ''){
        this.$toast.warning("Primero debe seleccionar la fecha de escritura");
        this.formSolicitud.escritura.fecha_autorizacion = null;
        return;
      }
      else{
        let fecha_escri = new Date(escritura.format("YYYY-MM-DD")+'T15:00:00');
        let fecha_autor = new Date(autorizacion.format("YYYY-MM-DD")+'T15:00:00');
        if(fecha_autor < fecha_escri){
          this.formSolicitud.escritura.fecha_autorizacion = null;
          this.formSolicitud.escritura.fecha_escritura = null;
          this.$toast.warning("La fecha de firma no puede ser menor a la fecha de escritura");
          return;
        }
        //@ts-check
        const diferencia = autorizacion.diff(escritura,'days');

        if(diferencia > 29){
          this.formSolicitud.escritura.fecha_autorizacion = null;
          this.formSolicitud.escritura.fecha_limite = null;
          this.$toast.warning("La fecha de firma no puede ser mayor de 30 días a la fecha de escritura");
          return;
        }
      }

      //Obtener uma subsidio
      let anioEscritura = parseInt(escritura.format("YYYY"));
      // @ts-ignore
      let item = this.catUmas.find(o => o.year === anioEscritura);
      // @ts-ignore
      this.valor_uma = item? item.valor_diario : this.catUmas[0].valor_diario;
      // @ts-ignore
      this.subsidio = (parseFloat(this.subsidio_vigente.valor) * this.valor_uma) * 365;
      // @ts-ignore
      this.subsidios[0].limite = (this.subsidio_vigente.dias_vivienda_interes_social * this.valor_uma) * 365;
      // @ts-ignore
      this.subsidios[1].limite = (this.subsidio_vigente.dias_vivienda_popular * this.valor_uma) * 365;
      
      let text = autorizacion.businessAdd(15).format("YYYY-MM-DD");
      // @ts-ignore
      this.formSolicitud.escritura.fecha_limite = text;
      this.fecha_limite = text;
      const date1 = this.fecha_limite;
      const date2 = this.fecha_actual.format("YYYY-MM-DD");
      this.diasInhabilesUsados = [];
      this.diasInhabiles.forEach(fecha => {
        this.fechaEstaEnRango(fecha, this.formSolicitud.escritura.fecha_autorizacion, this.fecha_limite);
      });
      const compareDates = (d1: any, d2: any) => {
        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
        let bandera = false;
        if (date1 < date2) {
          //console.log(`${d1} is less than ${d2}`);
          bandera = true
          this.tieneRecargos = true;
        } else if (date1 > date2) {
          this.tieneRecargos = false;
          //console.log(`${d1} is greater than ${d2}`);
        } else {
          this.tieneRecargos = false;
          //console.log(`Both dates are equal`);
        }
        return bandera;
      };
      const compara = compareDates(date1, date2);
      //Consulta recargos
      if(compara){
        this.getRecargosInpc(date2,date1);
      }
      else{
        this.limpiar();
      }
    },
    selectSub(){
      let item = this.subsidios.find(o => o.id === this.tipo_subsidio);
      this.limite_subsidio = item? item.limite : 0;
      this.limite_subsidio_text = item? '$' + new Intl.NumberFormat().format(item.limite) : '';
      this.subsidio_text= this.subsidio? '$' + new Intl.NumberFormat().format(this.subsidio) : '';
    },
    calcular(){
      if(this.formSolicitud.escritura.fecha_escritura == null){
        this.$toast.warning("Debe seleccionar la fecha de escritura");
        return;
      }
      if(this.formSolicitud.escritura.fecha_autorizacion == null){
        this.$toast.warning("Debe seleccionar la fecha de autorización");
        return;
      }
      if(this.avaluo == 0){
        this.$toast.warning("No tiene un precio pactado o avaluo");
        return;
      }
      
      this.bandera_calcular = true;
      if(this.aplica_sub){
        if(this.tipo_subsidio != 0){
          this.formSolicitud.calculo.aplica_subsidio = this.aplica_sub;
          this.formSolicitud.calculo.tipo_subsidio   = this.tipo_subsidio;
          if(this.avaluo < this.limite_subsidio){
            this.formSolicitud.calculo.base_gravable_completo = this.avaluo;
            this.formSolicitud.calculo.descuento_subsidio     = this.subsidio;
            this.base_gravable = this.avaluo-this.subsidio;
            if(Math.sign(this.base_gravable) == -1){
              this.base_gravable = 0;
            }
          }
          else{
            this.base_gravable = this.avaluo;
          }
        }
        else{
          this.$toast.warning("Debe seleccionar un tipo de subsidio");
        }
      }
      else{
        // @ts-ignore
        this.formSolicitud.calculo.tipo_subsidio       = null;
        // @ts-ignore
        this.formSolicitud.calculo.descuento_subsidio  = null;
        // @ts-ignore
        this.formSolicitud.calculo.base_gravable_completo = null;
        this.formSolicitud.calculo.aplica_subsidio     = this.aplica_sub;
        this.base_gravable = this.avaluo;
      }
      this.base_gravable_text = '$' + new Intl.NumberFormat().format(this.base_gravable);
      this.impuesto_neto = this.base_gravable * this.impuesto;
      this.impuesto_neto = parseFloat(this.impuesto_neto.toFixed(2));
      this.impuesto_neto_text = '$' + new Intl.NumberFormat().format(this.impuesto_neto);
      let impuesto_actualizado = 0;
      let recargos = 0;
      if(this.tieneRecargos){
        //INPC
        // @ts-ignore
        if(this.inpc.factor < 1){
          // @ts-ignore
          this.inpc.factor = 1;
        }
        // @ts-ignore
        impuesto_actualizado = this.inpc.factor * this.impuesto_neto;
        // @ts-ignore
        impuesto_actualizado = parseFloat((impuesto_actualizado - this.impuesto_neto).toFixed(2));
        // @ts-ignore
        console.log("el factor INPC",  this.inpc.factor);
        console.log("el Impuesto neto",  this.impuesto_neto);
        // @ts-ignore
        console.log("el factor * impuesto neto:",  this.inpc.factor * this.impuesto_neto);
        console.log("el impuesto actualizado",impuesto_actualizado);

        console.log("impuesto neto - impuesto actualizado", impuesto_actualizado - this.impuesto_neto);
        this.formSolicitud.calculo.impuesto_actualizado = impuesto_actualizado;
        this.inpc_texto = '$' + new Intl.NumberFormat().format(impuesto_actualizado);
        // @ts-ignore
        this.inpc_label = this.inpc.cadena;
        //Recargos
        // @ts-ignore
        recargos = this.recargos.tasa_moratoria * (this.impuesto_neto + impuesto_actualizado);
        // @ts-ignore
        recargos = parseFloat((recargos/100).toFixed(2));
        this.formSolicitud.calculo.recargo = recargos;
        this.recargos_texto = '$' + new Intl.NumberFormat().format(recargos);
        //@ts-ignore
        this.recargos_label = this.recargos.cadena;
      }
      else{
        this.formSolicitud.calculo.impuesto_actualizado = 0;
        this.inpc_texto = '';
        this.inpc_label = '';
        this.formSolicitud.calculo.recargo = 0;
        this.recargos_texto = '';
        this.recargos_label = '';
      }
      this.total_pagar = this.impuesto_neto + recargos + impuesto_actualizado;
      this.total_pagar = parseFloat(this.total_pagar.toFixed(2));
      this.total_pagar_text = '$' + new Intl.NumberFormat().format(this.total_pagar);
      //Editar
      if(this.editar){
        this.lectura = false;
      }
    },
    // @ts-ignore
    async Validar(){
        await SolicitudService.ValidarSoli({solicitud : this.isabiId}).then(
            (response) => {
                if(response.data.success){
                    this.$toast.success(response.data.message);
                }else{
                    this.$toast.error(response.data.message);
                }
              setTimeout(() => {
                location.reload();
              },2000)
            }
        );
    },
    // @ts-ignore
    async RechazarDialog(){
        this.formRechazo.id = this.isabiId.toString();
        this.dialog = true;
    },
    // @ts-ignore
    async RechazarEnvio(){
        if(this.formRe.validate()){
          await SolicitudService.RechazarSoli( this.formRechazo).then(
                (response) => {
                    if(response.data.success){
                        this.dialog = false;
                        this.$toast.success(response.data.message);
                    }else{
                        this.$toast.error(response.data.message);
                    }
                  setTimeout(() => {
                    location.reload();
                  },2000)
                }
            );
        }
    },
    async getRecargosInpc(date2: any, date1: any) {
      let dato = {fecha_actual: date2, fecha_limite: date1}
      try {
        const [inpcRes, tasaRes] = await Promise.all([
          this.serviceInpc.getAll(dato),
          this.serviceTasaRecargos.getAll(dato)
        ]);
        this.inpc = inpcRes.data;
        this.recargos = tasaRes.data;
        //Actualizar calculos inpc
        // @ts-ignore
        this.formSolicitud.calculo.inpc_actual_id = this.inpc.inpc_actual.id;
        // @ts-ignore
        this.formSolicitud.calculo.inpc_limite_id = this.inpc.inpc_limite.id;
        // @ts-ignore
        this.formSolicitud.calculo.factor = this.inpc.factor;
        // @ts-ignore
        this.formSolicitud.calculo.cadena_inpc = this.inpc.cadena;
        //Actualizar calculos recargos
        // @ts-ignore
        this.formSolicitud.calculo.tasa_recargo_id = this.recargos.tasa.id;
        // @ts-ignore
        this.formSolicitud.calculo.meses = this.recargos.meses;
        // @ts-ignore
        this.formSolicitud.calculo.tasa_moratoria = this.recargos.tasa_moratoria;
        // @ts-ignore
        this.formSolicitud.calculo.cadena_recargo = this.recargos.cadena;
      } catch (error) {
        // Maneja el error apropiadamente según tu lógica de la aplicación
        //@ts-ignore
        this.$toast.error(error.response.data.message);
      }
    },
    async update(){
      
      for (let index: number = 0; index < this.formAdquirientes.length; index++) {
        //@ts-ignore
        this.$refs.formAdquiriente[index].validate();
      }
      //@ts-ignore
      if (this.form.validate()) {
        //Validar cuando la base gravable es cero
        if(this.formSolicitud.calculo.base_gravable == 0){

        }
        if(this.bandera_calcular == false){
          this.$toast.warning("Debe calcular los impuestos");
          return;
        }
        this.formSolicitud.adquirientes              = Object.assign({},this.formAdquirientes);
        this.formSolicitud.enajenantes               = Object.assign({},this.formEnajenantes);
        this.formSolicitud.estatus                   = '1';
        this.formSolicitud.calculo.impuesto          = this.impuesto;
        this.formSolicitud.calculo.base_gravable     = this.base_gravable;
        this.formSolicitud.calculo.impuesto_neto     = this.impuesto_neto;
        this.formSolicitud.calculo.total_a_pagar     = parseFloat(this.total_pagar.toFixed(2));
        this.formSolicitud.inmueble.precio_pactado   = this.formSolicitud.inmueble.precio_pactado.replace(/[^\d.]/g, '');
        this.formSolicitud.inmueble.avaluo_catastral = this.formSolicitud.inmueble.avaluo_catastral.replace(/[^\d.]/g, '');
        this.formSolicitud.inmueble.avaluo_bancario  = this.formSolicitud.inmueble.avaluo_bancario.replace(/[^\d.]/g, '');
        this.formSolicitud.inmueble.avaluo_catastral_subdivision = this.formSolicitud.inmueble.avaluo_catastral_subdivision? this.formSolicitud.inmueble.avaluo_catastral_subdivision.replace(/[^\d.]/g, '') : '';
        let formdata = Object.assign({}, this.formSolicitud);
        formdata.usuario.telefono = formdata.usuario.telefono? formdata.usuario.telefono.replace("(", "").replace(")", "").replaceAll(" ", "") : '';

        let claves = Object.keys(formdata.adquirientes);
        for(let i=0; i< claves.length; i++){
          let clave = claves[i];
          if(formdata.adquirientes[parseInt(clave)].telefono){
            formdata.adquirientes[parseInt(clave)].telefono = formdata.adquirientes[parseInt(clave)].telefono
            .replace("(", "")
            .replace(")", "")
            .replaceAll(" ", "");
          }
        }

        let data_pre = null;
        data_pre = await SolicitudService.update(this.isabiId,formdata);
        let { data } = data_pre;
        if (data.success) {
          this.$toast.success(data.message);
          this.$router.push({name: 'isabi'}).catch(() => {});
        }
        else {
          this.$toast.warning(data.message);
        }
      }
    },
    limpiar(){
      this.inpc = {};
      this.recargos = {};
      //Actualizar calculos inpc
      this.formSolicitud.calculo.inpc_actual_id = null;
      this.formSolicitud.calculo.inpc_limite_id = null;
      this.formSolicitud.calculo.factor         = null;
      this.formSolicitud.calculo.cadena_inpc    = null;
      //Actualizar calculos recargos
      this.formSolicitud.calculo.tasa_recargo_id = null;
      this.formSolicitud.calculo.meses           = null;
      this.formSolicitud.calculo.tasa_moratoria  = null;
      this.formSolicitud.calculo.cadena_recargo  = null;
    },
    formateo(){
      let value = this.formSolicitud.inmueble.avaluo_catastral.replace('.', '').replace(',', '').replace(/\D/g, '')
      // @ts-ignore
      if(value == ""){
        return;
      }
      const options = { minimumFractionDigits: 2 }
      const result = new Intl.NumberFormat('en-US', options).format(
        parseFloat(value) / 100
      )
      this.formSolicitud.inmueble.avaluo_catastral = "$"+result;
    },
    fechaEstaEnRango(fechaEspecifica: any, fechaInicio:any, fechaFin: any) {
      // Convertir las fechas de texto a objetos Date
      let fechaEspecificaDate = new Date(fechaEspecifica);
      let fechaInicioDate = new Date(fechaInicio);
      let fechaFinDate = new Date(fechaFin);
      // Verificar si la fecha específica está dentro del rango
      if(fechaEspecificaDate >= fechaInicioDate && fechaEspecificaDate <= fechaFinDate){
        // @ts-ignore
        this.diasInhabilesUsados.push(fechaEspecifica);
      }
      //return fechaEspecificaDate >= fechaInicioDate && fechaEspecificaDate <= fechaFinDate;
    },
    roundToFourDecimals(number: number) {
      const factor = 100000;
      const shiftedNumber = number * factor;
      const roundedShiftedNumber = Math.round(shiftedNumber);
      return Math.round(roundedShiftedNumber / 10) / 10;
    },
    aplicarPorcentaje(){
      let avaluoCatastral   = parseFloat(this.formSolicitud.inmueble.avaluo_catastral_original.replace(/[^\d.]/g, ''));
      let porcentaje_avaluo = this.formSolicitud.inmueble.porcentaje_avaluo;
      if(isNaN(avaluoCatastral)){
        this.$toast.warning("Debe capturar la clave catastral o folio");
      }
      // @ts-ignore
      if (porcentaje_avaluo >= 1 && porcentaje_avaluo <= 100) {
        this.formSolicitud.inmueble.aplica_porcentaje_avaluo = this.aplica_porcentaje;
        // @ts-ignore
        let nuevoValor = avaluoCatastral * (porcentaje_avaluo/100);
        this.formSolicitud.inmueble.avaluo_catastral = '$' + new Intl.NumberFormat().format(nuevoValor);
      }
      else{
        this.formSolicitud.inmueble.aplica_porcentaje_avaluo = this.aplica_porcentaje;
        this.$toast.warning("El porcentaje debe ser un valor entre 1 y 100");
      }
    }
  },
})
